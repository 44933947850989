import { toHTML } from 'slack-markdown';

import { type DtoSharedAsset } from '@lp-lib/api-service-client/public';

import { type FAQGroup } from './types';

export class FAQGroupUtils {
  static FromSharedAsset(asset: DtoSharedAsset): FAQGroup {
    return {
      id: asset.id,
      label: asset.label,
      faqs: asset.data?.faqs ?? [],
    };
  }
}

export class FAQUtils {
  static RenderText(text: string) {
    return toHTML(text, {
      escapeHTML: false,
    });
  }
}
