import { type UserAuthenticatedResponse } from '../../services/api-service';

export const EMAIL_PATTERN = /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const ALL_LOGIN_TYPES = ['password', 'email', 'google'] as const;
export type LoginType = (typeof ALL_LOGIN_TYPES)[number];

export type SetLoginType = (loginType: LoginType | null) => void;
export type LoginSuccessCallback = (resp: UserAuthenticatedResponse) => void;

export interface GoogleLocationState {
  redirectTo: Nullable<string>;
}
