import { type ReactNode, useMemo, useState } from 'react';
import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import { MinusIcon } from '../icons/MinusIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { type FAQ } from './types';
import { FAQGroupUtils, FAQUtils } from './utils';

export function FAQRenderedParagraph(props: {
  className?: string;
  text: string;
}) {
  const rendered = useMemo(() => {
    return FAQUtils.RenderText(props.text);
  }, [props.text]);

  return (
    <p
      className={`message-template-preview break-words ${props.className}`}
      dangerouslySetInnerHTML={{
        __html: rendered,
      }}
    ></p>
  );
}

function Expandable(props: {
  children?: ReactNode;
  expand?: boolean;
  durationMs?: number;
}) {
  return (
    <div
      className={`grid transition-all`}
      style={{
        gridTemplateRows: props.expand ? '1fr' : '0fr',
        transitionDuration: `${props.durationMs ?? 500}ms`,
      }}
    >
      <div className='overflow-hidden'>{props.children}</div>
    </div>
  );
}

export function FAQItem(props: { faq: FAQ }) {
  const { faq } = props;
  const [opened, setOpened] = useState(false);

  return (
    <button
      type='button'
      className={`w-full btn-secondary p-4 text-left`}
      onClick={() => setOpened(!opened)}
    >
      <div className='w-full flex items-center justify-between gap-5'>
        <FAQRenderedParagraph text={faq.question} className='text-sms' />
        {opened ? (
          <MinusIcon className='w-5 h-5 fill-current' />
        ) : (
          <PlusIcon className='w-5 h-5 fill-current' />
        )}
      </div>
      <Expandable expand={opened} durationMs={200}>
        <div className='h-4'></div>
        <FAQRenderedParagraph text={faq.answer} className='text-sms' />
      </Expandable>
    </button>
  );
}

export function FAQList(props: { groupIds: string[] }) {
  const { data: faqs } = useSWR(['/faq', props.groupIds], async () => {
    if (!props.groupIds.length) return [];
    const sharedAssets = await apiService.media
      .querySharedAsset({
        type: 'by-ids',
        ids: props.groupIds.join(','),
      })
      .next();
    return sharedAssets
      .map((m) => FAQGroupUtils.FromSharedAsset(m))
      .flatMap((g) => g.faqs);
  });

  if (!faqs?.length) return null;

  return (
    <section className='w-full'>
      <div className='text-xl font-bold text-tertiary'>FAQs</div>
      <div className='mt-3 w-full flex flex-col gap-2'>
        {faqs.map((faq) => (
          <FAQItem key={faq.id} faq={faq} />
        ))}
      </div>
    </section>
  );
}
